
import { defineComponent } from 'vue'

import CloseIcon from '@/assets/svg/close-icon-small.svg?inline'

import LogInForm from '@/components/forms/Login.vue'

export default defineComponent({
  name: 'Popup',
  data () {
    return {
      translateData: {}
    }
  },
  props: {
    onClose: {
      type: Function,
      default () {
        return {}
      }
    }
  },
  components: {
    LogInForm,
    CloseIcon
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('to_dashboard')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    handleCloseModal () {
      this.onClose()
    }
  }
})
