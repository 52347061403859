import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/charity',
    name: 'Charity',
    component: () => import('../views/Charity.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/email-confirmed',
    name: 'EmailConfirmed',
    component: () => import('../views/EmailConfirmed.vue')
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: () => import('../views/HowItWorks.vue')
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: () => import('../views/Confirm.vue')
  },
  {
    path: '/mail-not-confirmed',
    name: 'MailNotConfirmed',
    component: () => import('../views/MailNotConfirmed.vue')
  },
  {
    path: '/password-forgot',
    name: 'PasswordForgot',
    component: () => import('../views/PasswordForgot.vue')
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    component: () => import('../views/PasswordRecovery.vue')
  },
  {
    path: '/dashboard/home',
    name: 'HomeDashboard',
    component: () => import('../views/dashboard/Home.vue')
  },
  {
    path: '/dashboard/replenishment',
    name: 'Replenishment',
    component: () => import('../views/dashboard/Replenishment.vue')
  },
  {
    path: '/dashboard/replenishment-error',
    name: 'ReplenishmentError',
    component: () => import('../views/dashboard/ReplenishmentError.vue')
  },
  {
    path: '/dashboard/output',
    name: 'Output',
    component: () => import('../views/dashboard/Output.vue')
  },
  {
    path: '/dashboard/my-wallets',
    name: 'MyWallets',
    component: () => import('../views/dashboard/MyWallets.vue')
  },
  {
    path: '/dashboard/history',
    name: 'History',
    component: () => import('../views/dashboard/History.vue')
  },
  {
    path: '/dashboard/my-structure',
    name: 'MyStructure',
    component: () => import('../views/dashboard/MyStructure.vue')
  },
  {
    path: '/dashboard/thank',
    name: 'Thank',
    component: () => import('../views/dashboard/Thank.vue')
  },
  {
    path: '/dashboard/thank-output',
    name: 'ThankOutput',
    component: () => import('../views/dashboard/ThankOutput.vue')
  },
  {
    path: '/dashboard/add-perfect',
    name: 'AddPerfect',
    component: () => import('../views/dashboard/AddPerfect.vue')
  },
  {
    path: '/dashboard/settings',
    name: 'Settings',
    component: () => import('../views/dashboard/Settings.vue')
  },
  {
    path: '/dashboard/new-wallet',
    name: 'NewWallet',
    component: () => import('../views/dashboard/NewWallet.vue')
  },
  {
    path: '/dashboard/info',
    name: 'Info',
    component: () => import('../views/dashboard/Info.vue')
  },
  {
    path: '/dashboard/select-wallet',
    name: 'SelectWallet',
    component: () => import('../views/dashboard/SelectWallet.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/how-it-works', '/registration', '/email-confirmed', '/charity', '/password-forgot', '/password-recovery', '/confirm']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
