import { createStore } from 'vuex'
import { auth } from '@/store/auth.module'
import { getPayments } from '@/store/user.module'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    getPayments
  }
})
