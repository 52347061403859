
import SelectIcon from '@/assets/svg/select-icon.svg?inline'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Lang',
  data () {
    return {
      langBody: false,
      lang: 'ua',
      selectLang: 'ua',
      langOption: ['en', 'ru', 'ua']
    }
  },
  props: {
    type: {
      type: String,
      default: null
    }
  },
  components: {
    SelectIcon
  },
  mounted () {
    this.selectLang = JSON.parse(localStorage.getItem('lang') || JSON.stringify('ua') || JSON.stringify('ru'))
  },
  methods: {
    changeLang (lang) {
      this.lang = lang
      localStorage.setItem('lang', JSON.stringify(lang))
      location.reload()
    },
    openLangBody () {
      if (this.langBody === true) {
        this.langBody = false
      } else this.langBody = true
    },
    closeLangBody () {
      this.langBody = false
    }
  }
})
