import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71059d68"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popup"
}
const _hoisted_2 = { class: "popup__container" }
const _hoisted_3 = { class: "popup__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_LogInForm = _resolveComponent("LogInForm")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "popup__close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args)))
          }, [
            _createVNode(_component_CloseIcon)
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(this.translateData.to_dashboard), 1),
          _createVNode(_component_LogInForm)
        ])
      ]))
    : _createCommentVNode("", true)
}