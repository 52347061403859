
import { defineComponent } from 'vue'
import HomeSection from '@/components/sections/HomeSection.vue'

export default defineComponent({
  name: 'Home',
  components: {
    HomeSection
  }
})
