<template>
  <div v-if="message" class="alert alert-danger" role="alert">
    {{ message }}
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AlertBlock',
  props: {
    message: {
      type: [String, Boolean],
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
  .alert {
    position: absolute;
    left: 20px;
    bottom: -15px;
    color: #fff;
  }
</style>
