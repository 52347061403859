
import { defineComponent } from 'vue'

import Group from '@/assets/svg/home-dashboard/group.svg?inline'
import Win from '@/assets/svg/home-dashboard/win.svg?inline'
import Withdraw from '@/assets/svg/home-dashboard/withdraw.svg?inline'

export default defineComponent({
  name: 'StatisticBlock',
  data () {
    return {
      statistics: {},
      translateData: {}
    }
  },
  components: {
    Group,
    Win,
    Withdraw
  },
  mounted () {
    this.translate()
    this.getStatistics()
  },
  methods: {
    translate (data) {
      data = ['total_people', 'total_deposited', 'total_withdrawn']
      data.push()
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getStatistics () {
      this.$store.dispatch('getPayments/getStatisticsGuest').then(
        (response) => {
          this.statistics = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
