import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f8e7a6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "footer"
}
const _hoisted_2 = {
  class: "footer__copy",
  target: "blanck",
  href: "https://ut.in.ua"
}
const _hoisted_3 = { class: "footer__social" }
const _hoisted_4 = {
  href: "https://t.me/X_Seven_1",
  target: "_blanc"
}
const _hoisted_5 = {
  href: "https://www.instagram.com/xseven.10.7/",
  target: "_blanc"
}
const _hoisted_6 = {
  href: "https://www.facebook.com/XSEVEN-106439775283607",
  target: "_blanc"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Telegram = _resolveComponent("Telegram")!
  const _component_Inst = _resolveComponent("Inst")!
  const _component_Facebook = _resolveComponent("Facebook")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", _hoisted_2, _toDisplayString(this.translateData.made_by_ut) + " " + _toDisplayString(_ctx.getFullYear), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(this.translateData.tech_support) + ":", 1),
          _createElementVNode("a", _hoisted_4, [
            _createVNode(_component_Telegram)
          ]),
          _createElementVNode("a", _hoisted_5, [
            _createVNode(_component_Inst)
          ]),
          _createElementVNode("a", _hoisted_6, [
            _createVNode(_component_Facebook)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}