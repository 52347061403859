import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
    'main-button',
    $props.isSearchStyleType && 'main-button--search',
    $props.disabled && 'main-button--disabled',
    $props.black && 'black'
  ]
    )
  }, [
    _createElementVNode("button", {
      class: "l-pointer",
      type: $props.type,
      disabled: $props.disabled
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_1)
  ], 2))
}