import axios from 'axios'
import { string } from 'yup'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class UserService {
  getTransactionId () {
    return axios.post(API_URL + 'payments/p-money/transactions/in', { payment_level: 1 }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  withdrawMoney (data) {
    return axios.post(API_URL + 'payments/p-money/transactions/out', {
      account: data.account,
      amount: data.amount
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getWallets () {
    return axios.get(API_URL + 'payments/p-money/wallets', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getStatistics () {
    return axios.get(API_URL + 'dashboard/statistics', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getStatisticsGuest () {
    return axios.get(API_URL + 'stats', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  resend () {
    return axios.post(API_URL + 'auth/email/verify/resend', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  newWallets (data: any) {
    return axios.post(API_URL + 'payments/p-money/wallets/create', {
      name: data.name,
      wallet_id: 'U' + data.wallet
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  translate (data: any) {
    return axios.post(API_URL + 'translate', {
      keys: data
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteWallet (data: any) {
    return axios.delete(API_URL + `payments/p-money/wallets/${data}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  updateProfile (data) {
    return axios.put(API_URL + 'users/profile', {
      name: data.name
    },
    { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteProfile () {
    return axios.post(API_URL + 'users/softDeleteUser', {
      delete: true
    },
    { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  history (url: string) {
    return axios.get(API_URL + 'payments/history' + url, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getProfile (url: string) {
    return axios.get(API_URL + 'users/profile', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getReferrals (data: any) {
    return axios.get(API_URL + 'referrals/' + '?' + data, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new UserService()
