
import { defineComponent } from 'vue'

import Logo from '@/assets/svg/logo.svg?inline'
import LogoWhite from '@/assets/svg/logo-white.svg?inline'
import MenuIcon from '@/assets/svg/menu-icon.svg?inline'
import UserIcon from '@/assets/svg/user-icon.svg?inline'
import UserIconMobile from '@/assets/svg/user-icon-mobile.svg?inline'
import CloseIcon from '@/assets/svg/close-icon.svg?inline'

import Lang from '@/components/molecules/Lang.vue'

export default defineComponent({
  name: 'UserHeader',
  data () {
    return {
      translateData: {},
      name: '',
      mobileMenu: false
    }
  },
  components: {
    Lang,
    Logo,
    LogoWhite,
    MenuIcon,
    UserIcon,
    CloseIcon,
    UserIconMobile
  },
  mounted () {
    this.translate()
    // this.name = JSON.parse(localStorage.getItem('userData') || '{}')
  },
  computed: {
    getUserData () {
      try {
        const filter = localStorage.getItem('userData')
        if (!filter) {
          return null
        }

        return JSON.parse(filter)
      } catch (e) {
        return null
      }
    }
  },
  methods: {
    translate (data) {
      data = []
      data.push('how_it_works', 'charity')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    openMobMenu () {
      this.mobileMenu = true
    },
    closeMobMenu () {
      this.mobileMenu = false
    }
  }
})
