
import { defineComponent } from 'vue'
import Popup from '@/components/molecules/PopupAuth.vue'

export default defineComponent({
  name: 'AuthButtonGroup',
  data () {
    return {
      isModalOpen: false,
      translateData: {}
    }
  },
  components: {
    Popup
  },
  computed: {
    getUserData () {
      try {
        const filter = localStorage.getItem('userData')
        if (!filter) {
          return null
        }

        return JSON.parse(filter)
      } catch (e) {
        return null
      }
    }
  },
  mounted () {
    this.translate()
  },
  methods: {
    handleCloseModal () {
      this.isModalOpen = false
    },
    modalOpen () {
      if (this.getUserData == null) {
        this.isModalOpen = true
      } else {
        this.$router.push({
          path: '/dashboard/home'
        })
      }
    },
    goToRegistration () {
      this.$router.push('/registration')
    },
    translate (data) {
      data = []
      data.push('register', 'to_dashboard')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
