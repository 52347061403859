
import { defineComponent } from 'vue'

import Logo from '@/assets/svg/logo.svg?inline'
import ArrowHowItWork from '@/assets/svg/arrow-how.svg?inline'

import FooterUT from '@/components/molecules/FooterUT.vue'
import AuthButtonGroup from '@/components/molecules/AuthButtonGroup.vue'
import UserHeader from '@/components/molecules/UserHeader.vue'
import StatisticBlock from '@/components/molecules/StatisticBlock.vue'
import MainButton from '@/components/atoms/buttons/MainButton.vue'

export default defineComponent({
  name: 'HomeSection',
  data () {
    return {
      translateData: {},
      users: null
    }
  },
  components: {
    FooterUT,
    AuthButtonGroup,
    UserHeader,
    Logo,
    ArrowHowItWork,
    MainButton,
    StatisticBlock
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('main_about_the_project', 'main_about_1', 'main_about_2', 'slogan_main', 'main_about_3', 'main_about_4', 'main_about_5', 'how_it_works')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    onSubmit () {
      this.$router.push('/dashboard/home')
    },
    goToHowItWork () {
      this.$router.push('/how-it-works')
    }
  },
  props: {
    msg: String
  }
})
