import UserService from '@/services/user.service'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const getPayments = {
  namespaced: true,
  state: initialState,
  actions: {
    getTransactionId ({ commit }: any) {
      return UserService.getTransactionId().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    withdrawMoney ({ commit }: any, data: any) {
      return UserService.withdrawMoney(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    resend ({ commit }: any) {
      return UserService.resend().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getWallets ({ commit }: any) {
      return UserService.getWallets().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getStatistics ({ commit }: any) {
      return UserService.getStatistics().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getStatisticsGuest ({ commit }: any) {
      return UserService.getStatisticsGuest().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    newWallets ({ commit }: any, data: any) {
      return UserService.newWallets(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    translate ({ commit }: any, data: any) {
      return UserService.translate(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    deleteWallet ({ commit }: any, id: any) {
      return UserService.deleteWallet(id).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    updateProfile ({ commit }: any, data: any) {
      return UserService.updateProfile(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    deleteProfile ({ commit }: any) {
      return UserService.deleteProfile().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getHistory ({ commit }: any, url: string) {
      return UserService.history(url).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getProfile ({ commit }: any, url: string) {
      return UserService.getProfile(url).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getReferrals ({ commit }: any, data: any) {
      return UserService.getReferrals(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    getTransactionId (state: any) {
      state.status.loggedIn = false
    },
    resend (state: any) {
      state.status.loggedIn = false
    }
  }
}
