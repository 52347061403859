
import { defineComponent } from 'vue'

import Telegram from '@/assets/svg/telegram.svg?inline'
import Inst from '@/assets/svg/inst.svg?inline'
import Facebook from '@/assets/svg/facebook.svg?inline'

export default defineComponent({
  name: 'FooterUT',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    Telegram,
    Inst,
    Facebook
  },
  mounted () {
    this.translate()
  },
  computed: {
    getFullYear () {
      return new Date().getFullYear()
    }
  },
  methods: {
    translate (data) {
      data = []
      data.push('tech_support', 'made_by_ut')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
